import React from 'react';
import moment from 'moment-timezone';
import pluck from 'ramda/src/pluck';
import CheckboxSelect from './shared/checkbox-select';
import { DatePicker, Space } from 'antd';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
// import InfiniteScroll from 'react-infinite-scroller';
// import 'antd/dist/antd.css';

class LiveEvents extends React.Component {
  state = {
    events: [],
    showFilters: true,
    filters: {
      eventTypes: [],
      facilities: [],
      users: [],
      inmates: [],
      startTime: null,
      endTime: null,
    },
    availableEventTypes: [],
    availableEventTypesHash: {},
    availableUsers: [],
    availableInmates: [],
    availableFacilities: [],
    timezone: null,
    filterVisible: true,
    page: 1,
    hasMore: true,
    isWidget: true,
  };

  constructor(props) {
    super(props);
    if (props.events) this.state.events = props.events;
    if (props.events_hash) this.state.availableEventTypes = props.events_hash;
    if (props.facilities) {
      this.state.availableFacilities = props.facilities.map((facility) => ({
        ...facility,
        label: facility.name,
        value: facility.id,
      }));
    }
    if (props.users) {
      this.state.availableUsers = props.users.map((user) => ({
        ...user,
        label: user.full_name,
        value: user.id,
      }));
    }
    if (props.inmates) {
      this.state.availableInmates = props.inmates.map((inmate) => ({
        ...inmate,
        label: inmate.full_name,
        value: inmate.id,
      }));
    }
    if (props.hasOwnProperty('showFilters')) this.state.showFilters = props.showFilters;
    if (props.timezone) this.state.timezone = props.timezone;

    if (this.state.availableEventTypesHash) {
      let hash = {};
      this.state.availableEventTypes.map((event) => {
        hash[event.value] = event.label;
      });
      this.state.availableEventTypesHash = hash;
    }

    this.renderEvent = this.renderEvent.bind(this);
    this.handleFilterEventTypesChange = this.handleFilterEventTypesChange.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleInmatesChange = this.handleInmatesChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleFacilitiesChange = this.handleFacilitiesChange.bind(this);
    this.fetchFilteredData = this.fetchFilteredData.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.fetchMore = this.fetchMore.bind(this);
  }


  componentDidMount() {
    App.cable.subscriptions.create(
      { channel: 'StorybookChannel' },
      {
        connected: () => {
          console.log('connected');
          this.setState({ ...this.state, live: true });
        },
        received: (event) => {
          if (this.state.showFilters) {
            this.setState({
              ...this.state,
              events: [event, ...this.state.events],
            });
          } else {
            this.setState({
              ...this.state,
              events: [event, ...this.state.events].slice(0, 5),
            });
          }
        },
        disconnected: () => {
          console.log('disconnected');
          this.setState({ ...this.state, live: false });
        },
        rejected: () => {
          console.log('rejected');
        },
      }
    );
      this.setState({ isWidget: this.props.isWidget });
  }

  normalizedEventType(event) {
    availableEventTypes.filter;
  }

  renderEvent(event) {
    const {
      availableEventTypesHash,
      timezone,
      filters: { eventTypes, users, inmates, facilities },
    } = this.state;

    const eventTypeValues = pluck('value', eventTypes || []);
    const userValues = pluck('value', users || []);
    const inmateValues = pluck('value', inmates || []);
    const facilityValues = pluck('value', facilities || []);

    if (
      (eventTypeValues.length && !eventTypeValues.includes(event.event_type)) ||
      (userValues.length && !userValues.includes(event.user_id)) ||
      (inmateValues.length && !inmateValues.includes(event.metadata.inmate_id)) ||
      (facilityValues.length && !facilityValues.includes(event.facility_id))
    ) {
      return null;
    } else {
      return (
        <li key={event.id}>
          <p>
            <span className='recorded-at'>
              {moment.tz(event.recorded_at, timezone).format('MM/DD/YYYY HH:mm:ss')}
            </span>
            {event.metadata && event.metadata.facility_name && (
              <span className='facility'>{event.metadata && event.metadata.facility_name}</span>
            )}
            <span className='user'>{event.metadata && event.metadata.user_full_name}</span>
            <span className={`label ${event.event_type}`}>
              {availableEventTypesHash[event.event_type]}
            </span>
            <span className='note' dangerouslySetInnerHTML={{ __html: event.note }} />
          </p>
        </li>
      );
    }
  }

  handleFilterEventTypesChange(eventTypes) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, eventTypes } },
      this.fetchFilteredData
    );
  }

  handleUsersChange(users) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, users } },
      this.fetchFilteredData
    );
  }

  handleInmatesChange(inmates) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, inmates } },
      this.fetchFilteredData
    );
  }

  handleStartTimeChange(time) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, startTime: time } },
      this.fetchFilteredData
    );
  }

  handleEndTimeChange(time) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, endTime: time } },
      this.fetchFilteredData
    );
  }

  handleFacilitiesChange(facilities) {
    this.setState(
      { ...this.state, filters: { ...this.state.filters, facilities } },
      this.fetchFilteredData
    );
  }

  fetchFilteredData() {
    // Reset events to an empty array
    this.setState({ events: [] }, () => {
      const { filters } = this.state;

      const mappedFilters = {};

      Object.entries(filters).forEach((filter) => {
        const values = filter[1];
        const key = filter[0];

        if (Array.isArray(values)) {
          mappedFilters[key] = values.map((v) => v.value);
        } else {
          mappedFilters[key] = values;
        }
      });

      axios.post('/live/filter.json', { filters: mappedFilters }).then(({ data }) => {
        this.setState({
          ...this.state,
          events: data.events,
          hasMore: data.events.length === 50,
        });
      });
    });
  }

  toggleFilters() {
    const element = document.getElementById('filters');
    if (this.filterVisible()) {
      element.style.display = 'none';
      this.setState({ ...this.state, filterVisible: false });
    } else {
      element.style.display = 'flex';
      this.setState({ ...this.state, filterVisible: true });
    }
  }

  filterVisible() {
    const element = document.getElementById('filters');
    return element?.style?.display !== 'none';
  }

  fetchMore() {
    console.log('fetching more');
    const { events, filters } = this.state;

    const lastId = events[events.length - 1]?.id;
    const mappedFilters = {};

    Object.entries(filters).forEach((filter) => {
      const values = filter[1];
      const key = filter[0];

      if (Array.isArray(values)) {
        mappedFilters[key] = values.map((v) => v.value);
      } else {
        mappedFilters[key] = values;
      }
    });

    axios.post('/live/filter.json', { lastId, filters: mappedFilters }).then(({ data }) => {
      this.setState({
        ...this.state,
        hasMore: data.events.length === 50,
        events: [...this.state.events, ...data.events],
      });
    });
  }



  render() {
    const {
      availableEventTypes,
      availableInmates,
      availableUsers,
      availableFacilities,
      events,
      hasMore,
      showFilters,
      filterVisible,
      filters: { eventTypes, inmates, users, facilities },
      isWidget
    } = this.state;

    return (
      <div
        id='scrollableDiv'
        // className={`live-dashboard ${isWidget ? 'widget-height' : 'live-height'}`}
        className="live-dashboard"
        style={{  height: isWidget ? 'auto' : '90vh', width: 'full', overflow: 'auto' }}
      >
        {showFilters && (
          <div>
            <div className='show-filters'>
              <button className='toggle-filters btn' onClick={this.toggleFilters}>
                {filterVisible ? 'Hide Filters' : 'Show Filters'}
              </button>
            </div>
            <div id='filters' className='filters'>
              <div className='filter-row'>
                <div className='events filter'>
                  <CheckboxSelect
                    id='event-dropdown'
                    options={availableEventTypes}
                    value={eventTypes}
                    onChange={this.handleFilterEventTypesChange}
                    valueType='Event Types'
                  />
                </div>
                <div className='users filter'>
                  <CheckboxSelect
                    id='user-dropdown'
                    options={availableUsers}
                    value={users}
                    onChange={this.handleUsersChange}
                    valueType='Officers'
                  />
                </div>
                <div className='inmates filter'>
                  <CheckboxSelect
                    id='inmate-dropdown'
                    options={availableInmates}
                    value={inmates}
                    onChange={this.handleInmatesChange}
                    valueType='Inmates'
                  />
                </div>
                <div className='facilities filter'>
                  <CheckboxSelect
                    id='facility-dropdown'
                    options={availableFacilities}
                    value={facilities}
                    onChange={this.handleFacilitiesChange}
                    valueType='Facilities'
                  />
                </div>
              </div>
              <div className='filter-row'>
                <div className='start-time filter'>
                  <Space direction='vertical' size={12}>
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format='YYYY-MM-DD HH:mm'
                      minuteStep={5}
                      placeholder='Start date'
                      onChange={this.handleStartTimeChange}
                      onOk={this.handleStartTimeChange}
                    />
                  </Space>
                </div>
                <div className='end-time filter'>
                  <Space direction='vertical' size={12}>
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format='YYYY-MM-DD HH:mm'
                      minuteStep={5}
                      placeholder='End date'
                      onChange={this.handleEndTimeChange}
                      onOk={this.handleEndTimeChange}
                    />
                  </Space>
                </div>
              </div>
            </div>
          </div>
        )}

        <InfiniteScroll
          dataLength={events.length}
          next={this.fetchMore}
          hasMore={showFilters && hasMore}
          scrollThreshold={0.7}
          scrollableTarget='scrollableDiv'
          loader={
            <p style={{ textAlign: 'center' }}>
              <b>Loading older events...</b>
            </p>
          }
          endMessage={
            showFilters && (
              <p style={{ textAlign: 'center' }}>
                <b>No more events.</b>
              </p>
            )
          }
        >
          <ul className='timeline items'>{events.map(this.renderEvent)}</ul>
        </InfiniteScroll>
      </div>
    );
  }
}

export default LiveEvents;
